<template>
    <div>
        <load-screen :is-loading="isLoading"></load-screen>
        <page-header :page-title="`${this.t('DASHBOARD.PAGE_TITLE')} Loteamento: ${nameAllotment}`"></page-header>
        <div class="main-container">
            <div class="mb-2 ml-2"><H5>Resumo de Lotes:</H5></div>
            <div class="d-flex overflow-auto" style="gap: 10px">
                <div class="flex-grow-1" v-for="iten in lots.lotsByStatus"
                     :key="iten.typeName">
                    <div class="info-stats4">
                        <div class="info-icon">
                            <i class="icon-map-pin"></i>
                        </div>
                        <div class="sale-num">
                            <h3>{{ iten.total }}</h3>
                            <p>{{ iten.typeName }}</p>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1" v-for="iten in lots.emptyStatus"
                     :key="iten.typeName">
                    <div class="info-stats4">
                        <div class="info-icon">
                            <i class="icon-map-pin"></i>
                        </div>
                        <div class="sale-num">
                            <h3>{{ 0 }}</h3>
                            <p>{{ iten.typeName }}</p>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <div class="info-stats4">
                        <div class="info-icon">
                            <i class="icon-layers2"></i>
                        </div>
                        <div class="sale-num">
                            <h3>{{ lots.lots }}</h3>
                            <p>Total Lotes</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gutters">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="card">
                        <div class="p-4 row">
                            <div class="col-12 d-flex flex-row justify-content-between" style="margin-top: -0.5rem">
                                <h5 class="card-title">Lotes por situação</h5>
                            </div>
                            <div class="main-container col-12">
                                <div id="chartReserveType" :style="blurLoadingR"></div>
                            </div>
                        </div>
                        <div v-if="!loadingReserve" :class="{ loader: false, fadeout: !loadingReserve}"
                             class="d-flex justify-content-center align-items-center">
                            <div class="d-flex justify-content-center align-items-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-2 ml-2"><H5>Resumo de Reservas:</H5></div>
            <div class="d-flex overflow-auto" style="gap: 10px">
                <div class="flex-grow-1" v-for="iten in reserves.reservesByType"
                     :key="iten.typeName">
                    <div class="info-stats4">
                        <div class="info-icon">
                            <i class="icon-lock-open"></i>
                        </div>
                        <div class="sale-num">
                            <h3>{{ iten.total }}</h3>
                            <p>{{ iten.typeName }}</p>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <div class="info-stats4">
                        <div class="info-icon">
                            <i class="icon-layers2"></i>
                        </div>
                        <div class="sale-num">
                            <h3>{{ reserves.reservesTotal }}</h3>
                            <p>Total Reservas</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gutters">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="card">
                        <div class="p-4 row">
                            <div class="col-12 d-flex flex-row justify-content-between" style="margin-top: -0.5rem">
                                <h5 class="card-title">{{ this.t('NAV.RESERVE') }}</h5>
                            </div>
                            <div class="main-container col-12">
                                <div id="chartReserve" :style="blurLoadingR"></div>
                            </div>
                        </div>
                        <div v-if="!loadingReserve" :class="{ loader: false, fadeout: !loadingReserve}"
                             class="d-flex justify-content-center align-items-center">
                            <div class="d-flex justify-content-center align-items-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        <div class="card-body pt-0">
                            <div id="visitorsReserve"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import PageHeader from "@/components/layouts/pageHeader";
import ApexChartss from "apexcharts";
import LoadScreen from "@/components/layouts/loadScreenComponent";
import Dashboard from "../../services/Dashboard";
import {mapState} from "vuex";

export default {
    name: "DashboardAllotment",
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    components: {
        PageHeader,
        LoadScreen,
    },
    data() {
        return {
            totalClients: 0,
            reserves: {},
            seriesReserve: [],
            chartReserve: null,
            chartOptionsReserve: {},
            loadingReserve: true,
            lots: {},
            series: [{
                data: [5, 2, 18, 20, 10, 5, 24, 20, 25, 17, 20, 22]
            }],
            chartOptions: {
                dataLabels: {
                    enabled: true
                },
                xaxis: {
                    categories: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
                        'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                },
                colors: ['#0e5e4e'],
            }
        }
    },
    async mounted() {
        if(this.allotments?.length){
            if (!this.allotments.find(i => i.id === this.$route.params.id)?.id){
                this.$router.push({name:'IndexAllotmant'});
            }
        }
        this.$store.commit('changeLoading', true);
        this.summaryLots();
        this.renderChartReserveType();
        this.renderChartReserve();
        await this.summaryReserves();
        this.$store.commit('changeLoading', false);
    },
    watch:{
        allotments(){
            if (!this.allotments.find(i => i.id === this.$route.params.id)?.id){
                this.$router.push({name:'IndexAllotmant'});
            }
        }
    },
    computed: {
        ...mapState({
            isLoading: 'isLoading',
            allotments: 'allotments',
        }),
        blurLoadingR() {
            if (!this.loadingReserve) {
                return '-webkit-filter: blur(5px)';
            }
            return '';
        },
        nameAllotment()
        {
            return this.allotments?.find(i => i.id === this.$route.params.id)?.text;
        },
    },
    methods: {
        renderChartReserveType() {
            Dashboard.resumReserveByType(this.$route.params.id).then(resp => {
                if (!resp.data?.statuses?.length) {
                    return;
                }
                let series = [];
                let categories = resp.data.categories;
                resp.data.statuses.forEach(statuse => {
                    if (statuse?.reserve_types?.length){
                        return;
                    }
                    if (Array.isArray(statuse.data)){
                        series.push({
                            name: statuse.name,
                            data: statuse.data,
                            color: statuse.color,
                        });
                    }else {
                        let d = [];
                        Object.keys(statuse.data).forEach((key) => {
                            d.push(statuse.data[key])
                        })
                        series.push({
                            name: statuse.name,
                            data: d,
                            color: statuse.color,
                        });
                    }
                })
                let options = {
                    series: series,
                    dataLabels: {
                        enabled: false,
                    },
                    chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        toolbar: {
                            show: true
                        },
                        zoom: {
                            enabled: true
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            dataLabels: {
                                total: {
                                    enabled: true,
                                    style: {
                                        fontSize: '13px',
                                        fontWeight: 900
                                    }
                                }
                            }
                        },
                    },
                    xaxis: {
                        type: 'string',
                        categories: categories,
                    },
                }
                var chart = new ApexChartss(document.getElementById("chartReserveType"), options);
                chart.render();
            })
        },
        summaryLots() {
            Dashboard.summaryLotsByAllotment(this.$route.params.id).then(resp => {
                this.lots = resp.data;
            })
        },
        async summaryReserves() {
            await Dashboard.summaryReservesByAllotment(this.$route.params.id).then(resp => {
                this.reserves = resp.data;
            })
        },
        getClients() {
            Dashboard.getClients().then((resp) => {
                this.totalClients = resp.data.total;
            }).catch(() => {})
        },
        async renderChartReserve(companyId) {
            companyId = localStorage.getItem('companyId');
            Dashboard.datasAnualReserve(companyId, this.$route.params.id).then(resp => {
                if (this.chartReserve) {
                    this.chartReserve.destroy();
                }
                let result = resp.data
                this.seriesReserve = [];

                this.seriesReserve.push({
                    name: result.name1,
                    data: result.reserves
                });

                this.seriesReserve.push({
                    name: result.name2,
                    data: result.reservesConfirmed
                });

                this.chartOptionsReserve = {
                    chart: {
                        height: '280',
                        type: 'area',
                        width: '100%',
                        defaultLocale: 'pt',
                        locales: [{
                            name: 'pt',
                            options: {
                                toolbar: {
                                    download: 'Download SVG',
                                    selection: 'Selecionar',
                                    selectionZoom: 'Selecionar Zoom',
                                    zoomIn: 'Aumentar Zoom',
                                    zoomOut: 'Reduzir Zoom',
                                    pan: 'Panorama',
                                    reset: 'Resetar Zoom',
                                }
                            }
                        }],
                    },
                    noData: {
                        text: 'Nenhum dado para ser carregado',
                        align: 'center',
                        verticalAlign: 'middle',
                    },

                    series: this.seriesReserve,
                    xaxis: {
                        convertedCatToNumeric: false,
                        categories: result.categories
                    },
                    colors: ['#0e5e4e', '#0FBC9A', '#23A88D'],
                }

                let elChartReserve = document.getElementById('chartReserve');
                this.chartReserve = new ApexChartss(elChartReserve, this.chartOptionsReserve);
                this.chartReserve.render();
                this.loadingReserve = true
            }).catch(() => {
                this.loadingReserve = true
            });
        },
    }
}
</script>

<style scoped>

</style>
